<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <h1>Customer Feedback</h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <validation-observer ref="customerFeedbackForm">
          <b-card>
            <b-tabs v-model="activeTab" fill>
              <b-tab title="Information" active>
                <b-form @submit.prevent="onSubmit">
                  <b-row>
                    <b-col class="text-right">
                      <b-form-group>
                        <b-button
                          type="button"
                          class="mr-1"
                          variant="outline-danger"
                          @click="goBack"
                        >
                          Back
                        </b-button>
                        <b-button
                          type="button"
                          variant="success"
                          @click="onEdit"
                        >
                          Edit
                        </b-button>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group>
                        <b-row>
                          <b-col>
                            <b-form-group
                              label="Reference Number"
                              label-for="v-refnum"
                            >
                              <b-form-input
                                disabled
                                id="v-refnum"
                                v-model="formData.refnum"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-form-group
                              label="How can we help you?"
                              label-for="v-feedback_type"
                            >
                              <b-form-input
                                disabled
                                v-model="formData.feedback_type"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-form-group
                              label="Subject"
                              label-for="v-feedback_subject"
                            >
                              <b-form-input
                                disabled
                                v-model="formData.feedback_subject"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-form-group
                              label="Description"
                              label-for="v-feedback_description"
                            >
                              <b-form-textarea
                                disabled
                                rows="10"
                                v-model="formData.feedback_description"
                              ></b-form-textarea>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-form-group
                              label="Email"
                              label-for="v-feedback_user"
                            >
                              <b-form-input
                                disabled
                                v-model="formData.feedback_user"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group
                              label="Name"
                              label-for="v-feedback_name"
                            >
                              <v-select
                                disabled
                                v-model="formData.feedback_name"
                                :options="users"
                                :reduce="(users) => users.value"
                                @option:selected="onSelectUser($event)"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-form-group
                              label="Phone No."
                              label-for="v-feedback_phone"
                            >
                              <b-form-input
                                disabled
                                v-model="formData.feedback_phone"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group
                              label="Feedback Status"
                              label-for="v-feedback_status"
                            >
                              <b-form-select
                                disabled
                                id="v-feedback_status"
                                v-model="formData.feedback_status"
                                :options="statuses"
                              ></b-form-select>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-form-group label-for="v-feedback_contact_type">
                              <b-row>
                                <b-col cols="12" md="3">
                                  <b-form-group>
                                    <label for="">Please contact me by</label>
                                  </b-form-group>
                                </b-col>
                                <b-col cols="6" md="2">
                                  <b-form-group>
                                    <b-form-checkbox
                                      v-model="formData.checked_email"
                                    >
                                      Email
                                    </b-form-checkbox>
                                  </b-form-group>
                                </b-col>
                                <b-col cols="6" md="2">
                                  <b-form-group>
                                    <b-form-checkbox
                                      v-model="formData.checked_phone"
                                    >
                                      Phone
                                    </b-form-checkbox>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-form-group>
                    </b-col>

                    <b-col>
                      <b-form-group>
                        <b-card class="border-secondary">
                          <b-row>
                            <b-col>
                              <h2>Assignment Information</h2>
                            </b-col>
                          </b-row>

                          <b-row>
                            <b-col>
                              <b-form-group
                                label="Priority"
                                label-for="v-priority"
                              >
                                <b-form-select
                                  disabled
                                  :options="priorities"
                                  v-model="formData.priority"
                                ></b-form-select>
                              </b-form-group>
                            </b-col>
                            <b-col>
                              <b-form-group
                                label="Date"
                                label-for="v-assigned_date"
                              >
                                <b-form-input
                                  disabled
                                  type="datetime-local"
                                  v-model="formData.preview_assigned_date"
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col>
                              <b-form-group
                                label="Assigned To"
                                label-for="v-assigned_to"
                              >
                                <v-select
                                  disabled
                                  v-model.number="formData.assigned_to"
                                  :options="users"
                                  :reduce="(users) => +users.item.id"
                                  @option:selected="onSelectUser($event)"
                                />
                              </b-form-group>
                            </b-col>
                            <b-col>
                              <b-form-group
                                label="Copy To"
                                label-for="v-copy_to"
                              >
                                <v-select
                                  disabled
                                  v-model.number="formData.copy_to"
                                  :options="users"
                                  :reduce="(users) => +users.item.id"
                                  @option:selected="onSelectUser($event)"
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col>
                              <b-form-group
                                label="Instructions"
                                label-for="v-assigned_instructions"
                              >
                                <b-form-textarea
                                  disabled
                                  v-model="formData.assigned_instructions"
                                ></b-form-textarea>
                              </b-form-group>
                            </b-col>
                          </b-row>

                          <b-row>
                            <b-col>
                              <h2>Ticket Closed</h2>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col>
                              <b-form-group
                                label="Closed By"
                                label-for="v-closed_by"
                              >
                                <v-select
                                  disabled
                                  v-model.number="formData.closed_by"
                                  :options="users"
                                  :reduce="(users) => +users.item.id"
                                  @option:selected="onSelectUser($event)"
                                />
                              </b-form-group>
                            </b-col>
                            <b-col>
                              <b-form-group
                                label="Date"
                                label-for="v-closed_date"
                              >
                                <b-form-input
                                  disabled
                                  type="datetime-local"
                                  v-model="formData.preview_closed_date"
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col>
                              <b-form-group
                                label="Remarks"
                                label-for="v-closed_remarks"
                              >
                                <b-form-textarea
                                  disabled
                                  v-model="formData.closed_remarks"
                                ></b-form-textarea>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-card>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form>
              </b-tab>
              <span v-if="$route.query.id">
                <b-tab title="Logs">
                  <Logs module="feedbackgeneral" />
                </b-tab>
                <b-tab title="Comments">
                  <Comments module="feedbackgeneral" />
                </b-tab>
              </span>
            </b-tabs>
          </b-card>
        </validation-observer>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import statusesApi from '@api/statuses';
import usersApi from '@api/users';
import { getUserData } from '@/auth/utils';
import feedbackGeneralApi from '@api/feedback_generals';

export default {
  name: 'CustomerFeedbackManage',
  components: {},
  data: () => ({
    isLoading: false,
    activeTab: 0,
    formData: {
      id: 0,
      company_id: null,
      ticket_no: null,
      priority: null,
      assigned_to: null,
      assigned_date: null,
      assigned_instructions: null,
      closed_date: null,
      closed_remarks: null,
      closed_by: null,
      rating: null,
      rating_date: null,
      rating_remarks: null,
      feedback_type: null,
      feedback_status: null,
      feedback_date: null,
      feedback_notes: null,
      feedback_subject: null,
      feedback_description: null,
      feedback_user: null,
      feedback_salutation: null,
      feedback_name: null,
      feedback_phone: null,
      feedback_country_code: null,
      feedback_area_code: null,
      feedback_contact_type: null,
      copy_to: null,
      date_assigned: null,
      date_approved: null,

      preview_assigned_date: null,
      preview_closed_date: null,
      checked_email: false,
      checked_phone: true,
    },
    users: [],
    priorities: [],
    statuses: [],
  }),
  computed: {
    onUpdate: {
      get() {
        return this.formData.id ? 'Update' : 'Create';
      },
    },
  },
  watch: {
    section(v) {
      this.openSection = v;
    },
  },
  mounted() {
    this.loadSystemCode();
    this.loadUsers();
    this.formData.preview_assigned_date = this.defaultDateTime();
    this.formData.preview_closed_date = this.defaultDateTime();

    if (+this.$route.query.id) {
      this.loadFeedbackGeneral();
    }
  },
  methods: {
    loadFeedbackGeneral() {
      feedbackGeneralApi
        .get(this.$route.query.id)
        .then(({ data }) => {
          const { assigned_date, closed_date, feedback_contact_type } = data;

          data.preview_assigned_date =
            this.inputDateTimeFromUnix(assigned_date);
          data.preview_closed_date = this.inputDateTimeFromUnix(closed_date);
          data.checked_email = feedback_contact_type.includes('Email');
          data.checked_phone = feedback_contact_type.includes('Phone');

          this.formData = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadUsers() {
      const params = {
        sort: '-created_at',
        page: { size: 1000 },
        status: 'O',
        filterExt: `roles=user&coyuser&custuser&tech&qcsemployee`,
      };

      const userData = getUserData();
      const { companyId } = userData;

      if (companyId) {
        params.filterExt += `,company_id=${companyId}`;
      }

      usersApi
        .list(params)
        .then(({ data }) => {
          const items = [];

          for (let i = 0; i < data.length; i++) {
            const item = data[i];

            let fullName = item.first_name;
            if (item.last_name) {
              fullName += ` ${item.last_name}`;
            }

            items.push({ label: fullName, value: fullName, item });
          }

          this.users = items;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadSystemCode() {
      const params = {
        sort: '-created_at',
        page: { size: 1000 },
        status: 'O',
        filterExt: 'code=custfbprio|custfbstatus',
      };

      statusesApi
        .list(params)
        .then(({ data, meta }) => {
          if (data != null) {
            const [prio] = data.filter((x) => {
              return x.code === 'custfbprio';
            });
            if (prio) {
              this.priorities = prio.selections;
            }

            const [status] = data.filter((x) => {
              return x.code === 'custfbstatus';
            });
            if (status) {
              this.statuses = status.selections;
            }
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmit() {
      const { checked_email: email, checked_phone: phone } = this.formData;
      const contact_type = [];

      if (email) {
        contact_type.push('Email');
      }

      if (phone) {
        contact_type.push('Phone');
      }

      this.formData.assigned_date = this.inputDateTimeToUnix(
        this.formData.preview_assigned_date
      );

      this.formData.closed_date = this.inputDateTimeToUnix(
        this.formData.preview_closed_date
      );

      this.formData.feedback_contact_type = contact_type.join(',');

      let app;
      if (!this.formData.id) {
        app = feedbackGeneralApi.add(this.formData);
      } else {
        app = feedbackGeneralApi.update(this.formData);
      }

      if (app) {
        app
          .then(({ data }) => {
            const url = `/pages/feedbacks/customer-feedbacks/view?id=${data.id}`;
            this.$router.push(url);
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      }
    },
    onEdit() {
      const url = `/pages/feedbacks/customer-feedbacks/manage?id=${this.$route.query.id}`;
      this.$router.push(url);
    },
    onSelectUser(user) {
      const { item } = user;

      this.formData.feedback_user = item.email;
      this.formData.feedback_phone = item.mobile;
    },
    goBack() {
      this.$router.push('/pages/feedbacks/customer-feedbacks');
    },
  },
};
</script>

<style lang="scss"></style>
